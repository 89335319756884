import React, { Component } from 'react'
import {
    Container,
} from 'react-bootstrap'

export default class nosotros extends Component {
    render() {
        return (
            <div id="nosotros-andes">
                <Container>
                    <h1 className="font-weight-bold" style={{ color: "#FF6751" }}>QUIENES SO<span id="linea-nos">MOS</span></h1>
                    <div className="text-justify">
                        <p style={{ marginTop: "9%" }}>
                            Andes Capacitación Ltda. es una empresa que ofrece servicios de capacitación a empresas y personas que trabajan en la
                            industria agrícola, pesquera, minera, metalmecánica y metalúrgica. La
                            modalidad de cursos a Empresas, está acogida a Franquicia Tributaria SENCE.
                        </p>

                        <p style={{ marginTop: "3%" }}>
                            Las Capacitaciones se realizan en modalidad cerrada y personalizada, cuyos
                            contenidos son adaptados de acuerdo a las necesidades y requerimientos específicos de los
                            clientes que los solicitan.
                        </p>

                        <h5 className="font-weight-bold" style={{ marginTop: "5%" }}>
                            Andes Capacitación Ltda. se encuentra certificada en la Norma Chilena (NCh) 2728:2015,
                            y está acreditada en el Registro del Servicio Nacional de Capacitación y Empleo (SENCE).
                        </h5>
                    </div>
                </Container>
            </div>
        )
    }
}
