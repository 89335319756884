import React, { Component } from 'react'
import {
    Col,
    Row,
    Container
} from 'react-bootstrap'
import cliente_1 from '../images/cliente_1.png'
import cliente_2 from '../images/cliente_2.png'
import cliente_3 from '../images/cliente_3.png'
import cliente_4 from '../images/cliente_4.png'
import cliente_5 from '../images/cliente_5.png'
import cliente_6 from '../images/cliente_6.png'
import cliente_7 from '../images/cliente_7.png'
import cliente_8 from '../images/cliente_8.png'
import cliente_9 from '../images/cliente_9.png'
import cliente_10 from '../images/cliente_10.png'
import cliente_11 from '../images/cliente_11.png'
import cliente_12 from '../images/cliente_12.png'
import cliente_13 from '../images/cliente_13.png'
import cliente_14 from '../images/cliente_14.png'
import cliente_15 from '../images/cliente_15.png'
import cliente_16 from '../images/cliente_16.png'

export default class nuestrosClientes extends Component {
    render() {
        return (
            <div id="nuestros-clientes">
                <Container>
                    <h1 className="font-weight-bold" style={{ color: "#FF6751" }}>NUESTROS CLIEN<span id="linea-nos">TES</span></h1>
                    <Row>
                        <Col md="3"><img src={cliente_1} alt="" /></Col>
                        <Col md="3"><img src={cliente_2} alt="" /></Col>
                        <Col md="3"><img src={cliente_3} alt="" /></Col>
                        <Col md="3"><img src={cliente_4} alt="" /></Col>
                    </Row>
                    <Row>
                        <Col md="3"><img src={cliente_5} alt="" /></Col>
                        <Col md="3"><img src={cliente_6} alt="" /></Col>
                        <Col md="3"><img src={cliente_7} alt="" /></Col>
                        <Col md="3"><img src={cliente_8} alt="" /></Col>
                    </Row>
                    <Row>
                        <Col md="3"><img src={cliente_9} alt="" /></Col>
                        <Col md="3"><img src={cliente_10} alt="" /></Col>
                        <Col md="3"><img src={cliente_11} alt="" /></Col>
                        <Col md="3"><img src={cliente_12} alt="" /></Col>
                    </Row>
                    <Row>
                        <Col md="3"><img src={cliente_13} alt="" /></Col>
                        <Col md="3"><img src={cliente_14} alt="" /></Col>
                        <Col md="3"><img src={cliente_15} style={{ marginTop:"15%" }} alt="" /></Col>
                        <Col md="3"><img src={cliente_16} style={{ marginTop:"10%" }} alt="" /></Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
