import React, { Component } from 'react'
import {
    Container,
    Col,
    Row,
} from 'react-bootstrap'
import capacitacion_efect from '../images/capacitacion_efect.png'
import cursos_personalizados from '../images/cursos_perso.png'
import certificado_sence from '../images/certificado_sence.png'

export default class iconsArea extends Component {
    render() {
        return (
            <div id="icons-area">
                <Container>
                    <Row>
                        <Col md="4">
                            <img src={capacitacion_efect} alt="Capacitación efectiva" />
                            <h5>CAPACITACIÓN</h5>
                            <h5>EFECTIVA</h5>
                        </Col>
                        <Col md="4">
                            <img src={cursos_personalizados} alt="Cursos personalizados" />
                            <h5>CRUSOS</h5>
                            <h5>PERSONALIZADOS</h5>
                        </Col>
                        <Col md="4">
                            <img src={certificado_sence} alt="Certificación sence" />
                            <h5>CERTIFICACIÓN</h5>
                            <h5>SENCE</h5>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
