import React, { Component } from 'react'
import {
    Container,
} from 'react-bootstrap'

export default class mision extends Component {
    render() {
        return (
            <div id="mision-andes">
                <Container>
                    <h1 className="font-weight-bold" style={{ color: "#FF6751" }}>MIS<span id="linea-nos">IÓN</span></h1>

                    <div className="text-justify">
                        <p style={{ marginTop: "9%" }}>
                            Con 15 años de trayectoria, la misión de ANDES es plantear retos y animar a las personas a
                            explotar sus capacidades innatas mediante un aprendizaje efectivo.
                        </p>

                        <p style={{ marginTop: "3%" }}>
                            Somos una empresa orientada a proporcionar un servicio eficaz y oportuno, observando a normativa legal vigente
                        </p>

                        <h3 className="text-center font-weight-bold" style={{ color: "#FF6751", marginTop: "5%" }}>
                            <span>ANDES ES INNOVACI&Oacute;N, EFECTIVIDAD,</span> <br />
                            <span>EXPERIENCIA, PASI&Oacute;N, IMPULSO, PRODUCTIVIDAD</span> <br />
                            <span>Y CONOCIMIENTO.</span>
                        </h3>
                    </div>
                </Container>
            </div>
        )
    }
}
