import React, { Component } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import {
    Card,
} from 'react-bootstrap'
import header from './header';

export default class previewCurs extends Component {
    render() {
        return (
            <div id="preview-curs">
                <Card>
                    <Card.Img variant="top" src={this.props.image} />
                    <Card.Body>
                        <Card.Title><span>{this.props.children}</span></Card.Title>
                        <Link className="btn btn-primary" to={this.props.url}>VER MÁS</Link>
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

previewCurs.propTypes = {
    image: PropTypes.string,
    url: PropTypes.string,
}

header.defaultProps = {
    image: '',
    url: '',
}