import React, { Component } from 'react'
import {
    Container,
    Row,
    Col,
} from 'react-bootstrap'
import PreviewCurs from './previewCurs'
import curso_pluma from '../images/curso_pluma.jpg'
import curso_altura from '../images/curso_altura.jpg'
import curso_auxilios from '../images/curso_axuilios.jpg'
import curso_montana from '../images/curso_alta_montana.jpg'
import curso_extintor from '../images/curso_extintor.jpg'
import curso_izaje from '../images/curso_izaje.jpg'
export default class nuestrosCursos extends Component {
    render() {
        return (
            <div id="nuestros-cursos">
                <Container>
                    <h1 className="font-weight-bold" style={{ color: "#FF6751" }}>NUESTROS CUR<span id="linea-nos">SOS</span></h1>
                    <div id="nuestros-cursos-text" className="text-justify">
                        <p style={{ marginTop: "9%" }}>
                            Coordinamos cursos que se ajustan a las necesidades de nuestros clientes, la preparación de las
                            clases están 100% pensadas en los objetivos de la empresa, los trabajadores y la productividad de
                            la compañía.
                        </p>
                    </div>
                    <h1 style={{ color: "#FF6751", fontWeight: "lighter", marginTop: "9%", marginBottom: "9%" }}>CURSOS DESTACADOS</h1>
                    <Row id="nuestros-cursos-preview">
                        <Col md="4">
                            <PreviewCurs image={curso_altura} url="/cursos/1237984232">T&Eacute;CNICAS DE TRABAJO SEGURO EN ANDAMIOS Y ALTURA F&Iacute;SICA</PreviewCurs>
                        </Col>
                        <Col md="4">
                            <PreviewCurs image={curso_pluma} url="/cursos/1237969668">T&Eacute;CNICAS DE OPERACI&Oacute;N DE MAQUINARIA PESADA</PreviewCurs>
                        </Col>
                        <Col md="4">
                            <PreviewCurs image={curso_montana} url="/cursos/1237969376">T&Eacute;CNICAS DE MANEJO DEFENSIVO EN ALTA MONTA&Ntilde;A</PreviewCurs>
                        </Col>
                    </Row>
                    <Row id="nuestros-cursos-preview" className="justify-content-center">
                        <Col md="4">
                            <PreviewCurs image={curso_extintor} url="/cursos/1237989808">USO Y MANEJO DE EXTINTORES</PreviewCurs>
                        </Col>
                        <Col md="4">
                            <PreviewCurs image={curso_auxilios} url="/cursos/1237989993">T&Eacute;CNICAS DE PRIMEROS AUX&Iacute;LIOS</PreviewCurs>
                        </Col>
                        <Col md="4">
                            <PreviewCurs image={curso_izaje} url="/cursos/1237969784">T&Eacute;CNICAS DE IZAJE</PreviewCurs>
                        </Col>
                    </Row>
                    <h1 style={{ color: "#FF6751", fontWeight: "lighter", marginTop: "9%", marginBottom: "9%" }}>TODOS NUESTROS CURSOS</h1>
                    <Row id="todos-nuestros-cursos">
                        <Col md="6">
                            <ul>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237978840">Manejo, Transporte y Almacenamiento de sustancias peligrosas</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237970125">Actualizaci&oacute;n de T&eacute;cnicas en la Operaci&oacute;n de Cami&oacute;n Pluma</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237970066">T&eacute;cnicas de Operaci&oacute;n Segura de Retroexcavadora</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237970071">Actualizaci&oacute;n de T&eacute;cnicas de Operaci&oacute;n Segura de Plataformas Elevadoras</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237971080">T&eacute;cnicas de Levante e Izaje de Material con Gr&uacute;as</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237974250">Operaci&oacute;n Segura de Puente Gr&uacute;a</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237970482">Actualizaci&oacute;n de T&eacute;cnicas de Operaci&oacute;n de Cargador Frontal</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237970445">T&eacute;cnicas de Operaci&oacute;n Segura de Minicargador Frontal</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237970987">Operaci&oacute;n y Mantenci&oacute;n de Sistemas de Aire Acondicionado de Equipos M&oacute;viles</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237985498">T&eacute;cnicas de Operaci&oacute;n, Mantenci&oacute;n y Reparaci&oacute;n de Aire Acondicionado de Equipos M&oacute;viles</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237982990">T&eacute;cnicas de Manipulaci&oacute;n de Equipos de Gases Comprimidos en Estado Criog&eacute;nico</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237991305">T&eacute;cnicas de Investigaci&oacute;n de Causas de Incidentes y Accidentes Laborales (Evita)</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237991301">T&eacute;cnicas de Supervici&oacute;n de Seguridad y Prevenci&oacute;n de Riesgos en L&iacute;nea de Mandos</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237991292">Manejo de Herramientas de Excel Intermedio</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237990365">T&eacute;cnicas de Manejo Defensivo</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237990241">T&eacute;cnicas de Prevenci&oacute;n de Riesgos en Miner&iacute;a (BASEDET)</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/search/?q=primeros+auxilios">T&eacute;cnicas de Primeros Aux&iacute;lios</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237989808">Uso y Manejo de Extintores</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237987205">Operaci&oacute;n y Mantenci&oacute;n de Bomba Centr&iacute;fuga</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237987199">T&eacute;cnicas de Lubricaci&oacute;n Equipos y Maquinarias Industriales</a></li>
                            </ul>
                        </Col>
                        <Col md="6">
                            <ul>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237986768">Manejo de Herramientas de Autocad Intermedio</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237986763">Manejo de Microsoft Project Intermedio</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237986721">T&eacute;cnicas de Rescate y control de Emergencias</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237986535">T&eacute;cnicas de Selecci&oacute;n, Montaje y Lubricaci&oacute;n de Rodamientos</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237984232">T&eacute;cnicas de Trabajo Seguro en Andamios y Altura F&iacute;sica</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237984231">T&eacute;cnicas en Soldadura</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237982928">Aplicar T&eacute;cnicas de Calderer&iacute;a y Trazado</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237989471">T&eacute;cnicas de Prevenci&oacute;n, Investigaci&oacute;n, Evaluaci&oacute;n y Control de Riesgos y Accidentes Laborales (M&eacute;todo ICAM)</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237982911">T&eacute;cnicas Aplicadas de Dibujo T&eacute;cnico</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237982450">T&eacute;cnicas de Coaching y Liderazgo Efectivo</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237979857">T&eacute;cnicas de Rescate en Altura F&iacute;sica</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237979632">T&eacute;cnicas de Planificaci&oacute;n Estrategica, Coaching y Liderazgo</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237978999">Curso B&aacute;sico de Ingl&eacute;s T&eacute;cnico</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237978610">T&eacute;cnicas de M&eacute;canica Diesel</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237977573">T&eacute;cnicas de Trabajo en Altura F&iacute;sica</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237977571">T&eacute;cnicas de Prevenci&oacute;n de Riesgos</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237970029">T&eacute;cnicas de Metrolog&iacute;a</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237969784">T&eacute;cnicas de Izaje</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237969668">Actualizaci&oacute;n de T&eacute;cnicas de Operaci&oacute;n de Maquinarias Pesadas</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237969400">T&eacute;cnicas de Auditar&iacute;a Interna</a></li>
                                <li><a style={{ color: "#606060" }} href="https://andescci.com/cursos/1237969376">T&eacute;cnicas de Manejo Defensivo en Alta Monta&ntilde;a</a></li>
                            </ul>
                            {/* <Button>DESCARGAR LISTADO<br />DE CURSOS</Button> */}
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
