import React, { Component, useState } from 'react'
import {
    Modal, Col, Row, Container
} from 'react-bootstrap'
import styles from './modal.css'
import logoMain from '../images/logo-main.png'
export default class dialog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: true
        }
        this.handleClose = this.handleClose.bind(this)
    }
    handleClose () {
        this.setState({
            show: false
        })
    }
    render() {
        return (
            <div style={{styles}}>   
                <Modal id="modal-main" show={this.state.show} onHide={this.handleClose}>
                    <div className="modal-content d-flex" id="modal-content">
                        <Modal.Header id="modal-header" closeButton>
                            <div className="col-4 offset-5 offset-lg-0" style={{ marginTop: "-.5rem" }}>
                                <Modal.Title className="justify-content-end"><img id="modal-logo" src={logoMain} alt={this.props.siteTitle} /></Modal.Title>    
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col id="modal-title">
                                    CURSO ABIE<span id="linea-rto">RTO</span>
                                </Col>
                            </Row>
                            
                            <Row>
                                <Col className="mx-lg-5" id="modal-text">
                                    El día Sábado 27 de Julio ANDES Presentará el curso abierto "Aplicaciones de Maniobras de Izaje (Rigger)" de <span id="modal-cupos">cupos limitados</span>, el cual será de 8 horas y con Certificación SENCE con una vigencia de 3 años.
                                </Col>
                            </Row>
                            <div className="container">
                                <Container>
                                    <div className="mx-auto">
                                        <a className="btn" id="modal-btn" href="https://www.facebook.com/events/365182377528277/">MÁS INFORMACIÓN</a>        
                                    </div>
                                </Container>
                            </div>                                                        
                        </Modal.Body>
                    </div>
                    
                </Modal>  
            </div>
        )
    }
}
