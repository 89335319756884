import React from "react"

import Layout from "../components/layout"
import HomeBanner from "../components/homeBanner"
import Nosotros from "../components/nosotros"
import Mision from '../components/mision'
import IconsArea from '../components/iconsArea'
import NuestrosCursos from '../components/nuestrosCursos'
// import Testimonios from '../components/testimonios'import Testimonios from '../components/testimonios'
import NuestrosClientes from '../components/nuestrosClientes'
import Mapa from '../components/mapa'
import SEO from "../components/seo"
import Dialog from '../components/dialog'

const IndexPage = () => (
  <Layout>
    <SEO title="Andes CCI" 
    description="Andes Capacitación Ltda. es una empresa que ofrece servicios de capacitación a empresas y personas que trabajan en la industria agricola, pesquera, minera, metalmecánica y metalúrgica.
    Las Capacitaciones se realizan en modalidad: abierta, cerrada y personalizada, cuyos contenidos son adaptados de acuerdo a las necesidades y requerimientos específicos de los clientes que los solicitan.
    Andes Capacitación Ltda. se encuentra certificada en la Norma NCH 2728 del 2015, acreditada en el Registro del Servicio Nacional de Capacitación y Empleo (SENCE). La modalidad de cursos a Empresas, está acogida a Franquicia Tributaria SENCE."
    keywords={[`andes`, 
    `capacitacion`,
    `efectiva`,
    `andes capacitacion`,
    `capacitacion efectiva`,
    `andes capacitacion efectiva`,
    `sence`,
    `capacitacion sence`,
    `norma`,
    `NCH:2728`,
    `norma nch:2728`,
    `otec`,
    `atacama`,
    `otec atacama`,
    `copiapo`,
    `otec copiapo`,
    `chile`,
    `mineria`,
    `otec mineria`,
    `camion`,
    `camion pluma`,
    `cargador`,
    `cargador frontal`,
    `minicargador`,
    `minicargador frontal`,
    `extintor`,
    `extintores`,
    `primeros auxilios`,
    `auxilios`,
    `altura`,
    `trabajo en altura`,
    `trabajo altura`,
    `maquinaria`,
    `pesada`,
    `maquinaria pesada`,
    `manejo`,
    `alta`,
    `montana`,
    `manejo alta montana`,
    `manejo defensivo`,
    `manejo defensivo alta montana`,
    `tecnicas de manejo defensivo`,
    `izaje`,
    `rigger`,
    `izaje y rigger`,
    `transporte`,
    `almacenamiento`,
    `sustancias`,
    `peligrosas`,
    `manejo sustancias`,
    `manejo sustancias peligrosas`,
    `almacenamiento sustancias`,
    `almacenamiento sustancias peligrosas`,
    `transporte sustancias`,
    `transporte sustancias peligrosas`,
    `manejo, almacenamiento y transporte de sustancias`,
    `manejo, almacenamiento y transporte de sustancias peligrosas`,
    `manejo, transporte y almacenamiento de sustancias`,
    `manejo, transporte y almacenamiento de sustancias peligrosas`,
    `transporte, manejo y almacenamiento de sustancias`,
    `transporte, manejo y almacenamiento de sustancias peligrosas`,
    `transporte, almacenamiento y manejo de sustancias`,
    `transporte, almacenamiento y manejo de sustancias peligrosas`,
    `almacenamiento, transporte y manejo de sustancias`,
    `almacenamiento, transporte y manejo de sustancias peligrosas`,
    `almacenamiento, manejo y transporte de sustancias`,
    `almacenamiento, manejo y transporte de sustancias peligrosas`,
    `tecnicas`,
    `tegnicas de operacion`,
    `tecnicas en la operacion`,
    `tecnicas de operacion de camion`,
    `tecnicas en la operacion de camion`,
    `tecnicas de operacion de camion pluma`,
    `tecnicas en la operacion de camion pluma`,
    `actualizacion tecnicas en la operacion de camion pluma`,
    `retroexcavadora`,
    `operacion segura`,
    `puente grua`,
    `operacion puente grua`,
    `operacion segura de puente grua`,
    `tecnicas de operacion segura`,
    `tecnicas de operacion segura de retroexcavadora`,
    `plataformas elevadoras`,
    `operacion segura de plataformas elevadoras`,
    `tecnicas de operacion segura de plataformas elevadoras`,
    `actualizacion de tecnicas de operacion segura de plataformas elevadoras`,
    `gruas`,
    `levante`,
    `grua`,
    `levante de material`,
    `izaje de material`,
    `levante e izaje`,
    `izaje y levante`,
    `izaje y levante con gruas`,
    `levante e izaje con gruas`,
    `levante e izaje de material`,
    `izaje y levante de material`,
    `levante e izaje de material con gruas`,
    `izaje y levante de material con gruas`,
    `tecnicas de izaje y levante con gruas`,
    `tecnicas de levante e izaje con gruas`,
    `tecnicas de izaje y levante de material`,
    `tecnicas de levante e izaje de material`,
    `tecnicas de izaje y levante de material con gruas`,
    `tecnicas de levante e izaje de material con gruas`,
    `operacion cargador`,
    `operacion minicargador`,
    `operacion cargador frontal`,
    `operacion segura cargador frontal`,
    `operacion minicargador frontal`,
    `operacion segura minicargador`,
    `operacion segura minicargador frontal`,
    `tecnicas de operacion cargador`,
    `tecnicas de operacion cargador frontal`,
    `tecnicas de operacion segura cargador`,
    `tecnicas de operacion segura cargador frontal`,
    `tecnicas de operacion minicargador`,
    `tecnicas de operacion minicargador frontal`,
    `tecnicas de operacion segura minicargador`,
    `tecnicas de operacion segura minicargador frontal`,
    `mantencion`,
    `reparacion`,
    `operacion y mantencion`,
    `mantencion y operacion`,
    `operacion, mantencion y reparacion`,
    `operacion, reparacion y mantencion`,
    `mantencion, operacion y reparacion`,
    `mantencion, reparacion y operacion`,
    `reparacion, operacion y mantencion`,
    `reparacion, mantencion y operacion`,
    `tecnicas de operacion, mantencion y reparacion`,
    `tecnicas de operacion, reparacion y mantencion`,
    `tecnicas de mantencion, operacion y reparacion`,
    `tecnicas de mantencion, reparacion y operacion`,
    `tecnicas de reparacion, operacion y mantencion`,
    `tecnicas de reparacion, mantencion y operacion`,
    `sistemas de aire acondicionado`,
    `aire acondicionado`,
    `operacion y mantencion de aire acondicionado`,
    `mantencion y operacion de aire acondicionado`,
    `operacion y mantencion de sistemas de aire acondicionado`,
    `mantencion y operacion de sistemas de aire acondicionado`,
    `operacion, mantencion y reparacion de aire acondicionado`,
    `operacion, reparacion y mantencion de aire acondicionado`,
    `mantencion, operacion y reparacion de aire acondicionado`,
    `mantencion, reparacion y operacion de aire acondicionado`,
    `reparacion, operacion y mantencion de aire acondicionado`,
    `reparacion, mantencion y operacion de aire acondicionado`,
    `tecnicas de operacion, mantencion y reparacion de aire acondicionado`,
    `tecnicas de operacion, reparacion y mantencion de aire acondicionado`,
    `tecnicas de mantencion, operacion y reparacion de aire acondicionado`,
    `tecnicas de mantencion, reparacion y operacion de aire acondicionado`,
    `tecnicas de reparacion, operacion y mantencion de aire acondicionado`,
    `tecnicas de reparacion, mantencion y operacion de aire acondicionado`,
    `operacion y mantencion de sistemas de sistemas de aire acondicionado`,
    `mantencion y operacion de sistemas de sistemas de aire acondicionado`,
    `operacion, mantencion y reparacion de sistemas de aire acondicionado`,
    `operacion, reparacion y mantencion de sistemas de aire acondicionado`,
    `mantencion, operacion y reparacion de sistemas de aire acondicionado`,
    `mantencion, reparacion y operacion de sistemas de aire acondicionado`,
    `reparacion, operacion y mantencion de sistemas de aire acondicionado`,
    `reparacion, mantencion y operacion de sistemas de aire acondicionado`,
    `tecnicas de operacion, mantencion y reparacion de sistemas de aire acondicionado`,
    `tecnicas de operacion, reparacion y mantencion de sistemas de aire acondicionado`,
    `tecnicas de mantencion, operacion y reparacion de sistemas de aire acondicionado`,
    `tecnicas de mantencion, reparacion y operacion de sistemas de aire acondicionado`,
    `tecnicas de reparacion, operacion y mantencion de sistemas de aire acondicionado`,
    `tecnicas de reparacion, mantencion y operacion de sistemas de aire acondicionado`,
    `curso camion`,
    `curso camion pluma`,
    `cursos`,
    `curso`,
    `curso extintores`,
    `auditoria`,
    `auditoria interna`,
    `tecnicas de auditoria interna`,
    `metrologia`,
    `tecnicas de metrologia`,
    `prevencion`,
    `riesgos`,
    `basedet`,
    `prevencion de riesgos`,
    `tecnicas de prevencion de riesgos`,
    `tecnicas de prevencion de riesgos en mineria`,
    `tecnicas de prevencion de riesgos en mineria basedet`,
    `fisica`,
    `altura fisica`,
    `trabajo en altura fisica`,
    `tecnicas de trabajo en altura fisica`,
    `tecnicas de primeros auxilios`,
    `diesel`,
    `mecanica`,
    `mecanica diesel`,
    `tecnicas de mecanica diesel`,
    `ingles`,
    `tecnico`,
    `ingles tecnico`,
    `curso basico`,
    `curso basico de ingles tecnico`,
    `planificacion`,
    `estrategica`,
    `coaching`,
    `liderazgo`,
    `tecnicas de coaching y liderazgo efectivo`,
    `planificacion estrategica`,
    `planificacion estrategica, coaching y liderazgo`,
    `rescate`,
    `rescate en altura fisica`,
    `tecnicas de rescate en altura fisica`,
    `dibujo`,
    `dibujo tecnico`,
    `tecnicas aplicadas de dibujo tecnico`,
    `caldereria`,
    `trazado`,
    `tecnicas de caldereria y trazado`,
    `aplicar tecnicas de caldereria y trazado`,
    `manipulacion`,
    `termos`,
    `gases`,
    `comprimidos`,
    `estado`,
    `criogenico`,
    `manipulacion de equipos y termos de gases comprimidos`,
    `manipulacion de equipos y termos de gases comprimidos en estado criogenico`,
    `tecnicas manipulacion de equipos y termos de gases comprimidos`,
    `tecnicas manipulacion de equipos y termos de gases comprimidos en estado criogenico`,
    `soldadura`,
    `tecnologia en soldadura`,
    `rodamientos`,
    `montaje`,
    `lubricacion`,
    `rodamientos`,
    `seleccion, montaje y lubricacion de rodamientos`,
    `tecnicas de seleccion, montaje y lubricacion de rodamientos`,
    `control`,
    `emergencias`,
    `mineria`,
    `subterranea`,
    `rescate en mineria subterranea`,
    `control de emergencias en mineria subterranea`,
    `rescate y control de emergencias en mineria subterranea`,
    `tecnicas de rescate y control de emergencias en mineria subterranea`,
    `microsoft`,
    `project`,
    `manejo de microsoft proyect intermedio`,
    `autocad`,
    `manejo de herramientas de autocad intermedio`,
    `tecnicas de lubricacion de equipos y maquinarias industriales`,
    `bomba`,
    `centrifuga`,
    `operacion y mantencion de bomba centrifuga`,
    `investigacion`,
    `evaluacion`,
    `accidentes`,
    `laborales`,
    `metodo`,
    `icam`,
    `prevencion, investigacion, evaluacion y control de riesgos`,
    `prevencion, investigacion, evaluacion y control de riesgos y accidentes laborales`,
    `prevencion, investigacion, evaluacion y control de riesgos y accidentes laborales metodo icam`,
    `tecnicas de prevencion, investigacion, evaluacion y control de riesgos y accidentes laborales`,
    `tecnicas de prevencion, investigacion, evaluacion y control de riesgos y accidentes laborales metodo icam`,
    `uso y manejo de extintores`,
    `excel`,
    `manejo de herramientas de excel intermedio`,
    `evita`,
    `investigacion de causas de incidentes y accidentes laborales`,
    `tecnicas de investigacion de causas de incidentes y accidentes laborales`,
    `tecnicas de investigacion de causas de incidentes y accidentes laborales evita`,
    `supervision de seguridad en linea de mandos`,
    `prevencion de riesgos en linea de mandos`,
    `supervision de seguridad y prevencion de riesgos en linea de mandos`,
    `tecnicas de supervision de seguridad y prevencion de riesgos en linea de mandos`
    ]} />
    <HomeBanner />
    <Nosotros />
    <Mision />
    <IconsArea />
    <NuestrosCursos />
    {/* <Testimonios /> */}
    <NuestrosClientes />
    <Mapa />
    {/* <Dialog /> */}
  </Layout>
)

export default IndexPage
